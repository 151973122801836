import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./FilterAccordeon.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const FilterAccordeon = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { title } = props;
  const [isOpen, setOpen] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const refWrap = useRef(null);
  const refHiddenBtn = useRef(null);
  const refHiddenBody = useRef(null);
  const refHiddenInner = useRef(null);
  const [checkboxList, setCheckboxList] = useState([]);
  const [checkboxListHidden, setCheckboxListHidden] = useState([]);
  const { isMobile } = state;
  const clickHandler = () => {
    setOpen(!isOpen);
  };
  useEffect(() => {
    const wrap = refWrap.current;
    if (isMobile) return;
    const clickHandler = (e) => {
      const withinBoundaries = e.composedPath().includes(wrap);
      if (withinBoundaries) {
        return;
      }
      setOpen(false);
    }
    if (isOpen) {
      wrap.classList.add(styles.active);
      document.addEventListener('click', clickHandler);
    } else {
      wrap.classList.remove(styles.active);
      document.removeEventListener('click', clickHandler);
    }
    return () => {
      document.removeEventListener('click', clickHandler);
    }
  }, [isOpen, isMobile]);

  useEffect(() => {
    const array = [...props.children];
    if (isMobile && array.length > 4) {
      const shown = array.slice(0, 4);
      const hidden = array.slice(4, array.length - 1);
      setCheckboxList(shown);
      setCheckboxListHidden(hidden);
    } else {
      setCheckboxList([...props.children]);
    }

  }, [props.children, isMobile]);

  useEffect(() => {
    const btn = refHiddenBtn.current;
    const body = refHiddenBody.current;
    const inner = refHiddenInner.current;
    if(!isMobile || checkboxListHidden.length === 0) return;
    if (isCollapsed) {
      btn.classList.add(styles.active);
      body.style.height = `${inner.offsetHeight}px`;
    } else {

      btn.classList.remove(styles.active);
      body.style.height = ``;
    }
  }, [checkboxListHidden, isCollapsed, isMobile]);
  let collapsedItems = null;
  if (checkboxListHidden.length > 0 && isMobile) {
    collapsedItems = <div className={styles.hidden}>
      <div className={styles.hidden__btn} onClick={() => { setCollapsed(prev => !prev) }} ref={refHiddenBtn}>
        Показать все
        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 6L13 1" stroke="#11559B" />
        </svg>

      </div>
      <div className={styles.hidden__body} ref={refHiddenBody}>
        <div className={styles.hidden__inner} ref={refHiddenInner}>
          {checkboxListHidden}
        </div>
      </div>
    </div>
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.header} onClick={clickHandler}>
          <span>{title}</span>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 20L16 12C18.5713 14.5713 24 20 24 20" stroke="#26262D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className={styles.body}>
          <div className={styles.scroll}>
          <div className={styles.inner}>
            {checkboxList}
          </div>
          </div>

          {collapsedItems}
        </div>
      </div>
    </>
  );

}

export default FilterAccordeon;
