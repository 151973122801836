import React, { useContext, useEffect, useRef } from "react";
import styles from "./Filter.module.scss";
import PathContext from "../../context/path";
import LibContext from "../../context/library";
import FilterCheckbox from '../FilterCheckbox/FilterCheckbox';
import FilterAccordeon from '../FilterAccordeon';
const Filter = () => {
  const path = useContext(PathContext);
  const lib = useContext(LibContext);
  const { dataFilters, isFilterMobileOpen, resetQueries, updateFilterMobileOpen } = lib;
  const refWrap = useRef(null);
  const filterNameMap = {
    'applicants': 'Тип заявителя',
    'geographies': 'География',
    'directions': 'Направление',
    'years': 'Год',
  }
  const filterTypeMap = {
    'applicants': 'type',
    'geographies': 'location',
    'directions': 'name',
    'years': 'year',
  }

  let filterItems = [];

  for (const key in filterNameMap) {
    let propArray = Object.getOwnPropertyNames(dataFilters);
    if (propArray.indexOf(key) !== -1) {
      let checkboxElements = dataFilters[key].map((element, index) => {
        let type = element[filterTypeMap[key]];

        return <div className={styles.item} key={`item-${key}-${index}`}>
          <FilterCheckbox query={key} id={element.id} name={type} />
        </div>
      });

      let accordeonElement = <div className={styles.accordeon} key={`accordeon-${key}`}>
        <FilterAccordeon title={filterNameMap[key]}>
          {checkboxElements}
        </FilterAccordeon>
      </div>
      filterItems.push(accordeonElement);
    }
  }
  useEffect(() => {
    const wrap = refWrap.current;
    const body = document.body;
    const root = document.querySelector('#root');
    if (isFilterMobileOpen) {
      wrap.classList.add(styles.active);
      body.style.overflow = 'hidden';
      root.style.overflow = 'hidden';
    } else {

      wrap.classList.remove(styles.active);
      body.style.overflow = '';
      root.style.overflow = '';
    }
    return () => {
      wrap.classList.remove(styles.active);
      body.style.overflow = '';
      root.style.overflow = '';
    }
  }, [isFilterMobileOpen]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.header}>
          <div className={styles.header__link} onClick={resetQueries}>Сбросить все </div>
          <div className={styles.title}>Фильтры</div>
          <div className={styles.header__link} onClick={()=>{updateFilterMobileOpen(false)}}>Закрыть</div>
        </div>
        <div className={styles.body}>
          <div className={styles.inner}>
            <div className={styles.list}>
              {filterItems}
            </div>
          </div>
        </div>
        <div className={styles.btn} onClick={()=>{updateFilterMobileOpen(false)}}>Применить</div>
      </div>

    </>
  );

}

export default Filter;
