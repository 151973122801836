import React, { useContext, useEffect, useState } from "react";
import { Outlet, useMatch } from 'react-router-dom';
import StateContext from "../../context/state";
import Header from '../../components/Header';
const Wrap = () => {

  const state = useContext(StateContext);
  const { stage, step, updateStep, updateStage, isMobile } = state;
  const [currentStage, setCurrentStage] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [headerTheme, setHeaderTheme] = useState('');
  const [currentPath, setCurrentPath] = useState('');

  const useCurrentPath = () => {
    const id = useMatch('/:id');
    if (id !== null && id.params.id !== undefined) {
      return id.params.id;
    }
    return 'main';
  }
  const tempPath = useCurrentPath().toLowerCase();

  useEffect(() => {

    // const invertTriggerHandler = () => {
    //   let rem = window.innerWidth * 1.11 / 100;
    //   if (isMobile) {
    //     rem = window.innerWidth * 4.267 / 100;
    //   }
    //   const headerTriggers = document.querySelectorAll(`[data-header-trigger]`);
    //   let isTriggered = false;
    //   if (headerTriggers.length > 0) {
    //     headerTriggers.forEach(element => {
    //       const b_offset = element.dataset.headerTriggerOffsetBottom ? element.dataset.headerTriggerOffsetBottom * rem : 0;
    //       const t_offset = element.dataset.headerTriggerOffsetTop ? element.dataset.headerTriggerOffsetTop * rem : 0;
    //       if (element.getBoundingClientRect().top + t_offset <= 0 && element.getBoundingClientRect().top + element.offsetHeight - b_offset >= 0) {
    //         isTriggered = true;
    //       }
    //     });
    //   }
    //   if (isTriggered) {
    //     setHeaderTheme('invert');
    //   } else {
    //     setHeaderTheme('');
    //   }
    // }
    // invertTriggerHandler();
    const lazyHandler = () => {
      const lazyTrigger = document.querySelectorAll('[data-lazy]:not(.lazy)');
      if (lazyTrigger.length === 0) return;
      lazyTrigger.forEach(element => {
        if (element.getBoundingClientRect().top < window.innerHeight * 2) {
          element.classList.add('lazy');
          const dataSrc = element.querySelector('[data-src]');
          const dataSrcset = element.querySelector('[data-srcset]');
          if (dataSrc !== null) {
            dataSrc.src = dataSrc.dataset.src;
            dataSrc.addEventListener('load', () => {
              element.classList.add('loaded');
            });
          }

          if (dataSrcset !== null) {
            dataSrcset.srcset = dataSrcset.dataset.srcset;
          }
        }
      });
    }
    lazyHandler();

    const triggerHandler = () => {
      const stageTriggers = document.querySelectorAll('[data-stage-trigger]');
      if (stageTriggers.length === 0) return;
      let array = [];
      if (stageTriggers.length > 0) {
        stageTriggers.forEach(element => {
          if (element.getBoundingClientRect().top - 100 <= 0) {
            array.push(element);
          }
        });
      }
      if (array[array.length - 1] !== undefined) {
        const tempStage = parseInt(array[array.length - 1].dataset.stageTrigger, 10);
        const tempStep = parseInt(array[array.length - 1].dataset.stepTrigger, 10);
        updateStage(tempStage);
          updateStep(tempStep);
      }
    }
    triggerHandler();
    document.addEventListener('scroll', triggerHandler);
    document.addEventListener('scroll', lazyHandler);
    // document.addEventListener('scroll', invertTriggerHandler);
    window.addEventListener('resize', triggerHandler);
    window.addEventListener('resize', lazyHandler);
    // window.addEventListener('resize', invertTriggerHandler);
    return () => {
      document.removeEventListener('scroll', triggerHandler);
      // document.removeEventListener('scroll', invertTriggerHandler);
      window.removeEventListener('resize', triggerHandler);
      document.removeEventListener('scroll', lazyHandler);
      window.removeEventListener('resize', lazyHandler);
      // window.removeEventListener('resize', invertTriggerHandler);
    }
  }, [currentStage, currentStep, updateStage, updateStep, step, tempPath, isMobile]);
  return (
    <>
      <div className="wrap">
        <Header theme={headerTheme} />
        <Outlet />
      </div>
    </>
  );

}

export default Wrap;
