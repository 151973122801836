import React, { useContext, useEffect, useRef } from "react";
import styles from "./SortPopup.module.scss";
import PathContext from "../../context/path";
import LibContext from "../../context/library";
const SortPopup = () => {
  const path = useContext(PathContext);
  const lib = useContext(LibContext);
  const refWrap = useRef(null);
  
  const { updateMobileSortId, updateSortPopup,  mobileSortPopup } = lib;
  useEffect(() => {
    const wrap = refWrap.current;
    const body = document.body;
    const root = document.querySelector('#root');
    if (mobileSortPopup) {
      wrap.classList.add(styles.active);
      body.style.overflow = 'hidden';
      root.style.overflow = 'hidden';
    } else {

      wrap.classList.remove(styles.active);
      body.style.overflow = '';
      root.style.overflow = '';
    }
    return () => {
      wrap.classList.remove(styles.active);
      body.style.overflow = '';
      root.style.overflow = '';
    }
  }, [mobileSortPopup]);
  const selectSortId = (num)=>{
    updateMobileSortId(num);
    updateSortPopup(false);
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.overlay}></div>
        <div className={styles.body}>
          <div className={styles.list}>
            <div className={styles.title}>Сортировать по</div>
            <div className={styles.link} onClick={()=>selectSortId(0)}>Началу</div>
            <div className={styles.link} onClick={()=>selectSortId(1)}>Дедлайну</div>
            <div className={styles.link} onClick={()=>selectSortId(2)}>Алфавиту</div>
          </div>
          <div className={styles.btn} onClick={()=>updateSortPopup(false)}>Отменить</div>
        </div>
      </div>
    </>
  );

}

export default SortPopup;
