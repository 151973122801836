import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import './fonts/stylesheet.css';
import './index.scss';

import App from './App';

smoothscroll.polyfill();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<StrictMode>
	  <App />
	</StrictMode>
  );
document.addEventListener('DOMContentLoaded',()=>{
	document.querySelector('body').style.opacity = 1;
  })