import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./FilterCheckbox.module.scss";
import PathContext from "../../context/path";
import LibContext from "../../context/library";
const FilterCheckbox = (props) => {
  const path = useContext(PathContext);
  const lib = useContext(LibContext);
  const refCheckbox = useRef(null);
  const { query, id, name, type } = props;
  const { searchQueries, updateSearchQueries, updateListType, listType } = lib;
  const [isChecked, setChecked] = useState(false);
  useEffect(() => {
    if (query === 'view') return;
    const checkbox = refCheckbox.current;
    const queries = { ...searchQueries };
    const checkQuery = queries[query] !== undefined;
    const checkId = checkQuery ? queries[query].filter(element => element.id === id).length > 0 : false;
    checkbox.checked = checkId;
    setChecked(checkId);
  }, [id, query, searchQueries]);
  useEffect(()=>{
    const checkbox = refCheckbox.current;
    if(id === listType && query === 'view'){
      checkbox.checked = true;
    }
  },[id, listType, query]);
  const changeHandler = () => {
    if (type === 'radio') {
      if (!isChecked) {
        updateListType(id)
      }
      return;
    }
    if (isChecked) {
      updateSearchQueries('remove', query, id);
    } else {
      updateSearchQueries('add', query, id, name);
    }
  }
  return (
    <>
      <label htmlFor={`checkbox-${query}-${id}`} className={styles.wrap}>
        <input type={type === 'radio' ? type : "checkbox"} className={styles.input} name={query} id={`checkbox-${query}-${id}`} ref={refCheckbox} onChange={changeHandler} />
        <div className={type === 'radio' ? `${styles.icon} ${styles.icon_radio}` : styles.icon}>
          {type === 'radio' ?
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="12" height="12" rx="6" fill="white" />
            </svg>
            :
            <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 0.760742L4.67969 7.41504C3.49757 6.23292 1.0018 3.73716 1.0018 3.73716" stroke="#F3FAFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          }
        </div>
        <div className={styles.name}>{name}</div>
      </label>
    </>
  );

}

export default FilterCheckbox;
