import React, { useContext, useEffect, useRef, useState } from "react";
import textStyles from "../Text/Text.module.scss";
import styles from "./Header.module.scss";
import StateContext from "../../context/state";
import PathContext from "../../context/path";
import { Link, useMatch } from 'react-router-dom';
const Header = (props) => {
  const state = useContext(StateContext);
  const path = useContext(PathContext);
  const { theme } = props;
  const { isMobile, stage, step, updateStep } = state;
  const [menuOpen, setMenuOpen] = useState(false);
  const [collapseOpened, setCollapseOpened] = useState(-1);

  const refWrap = useRef(null);
  const refBurger = useRef(null);
  const refOverlay = useRef(null);
  const refMenu = useRef(null);
  const refCollapse = useRef([]);
  const refCollapseBody = useRef([]);
  const refCollapseInner = useRef([]);

  const refSteps = useRef([]);
  const useCurrentPath = () => {
    const id = useMatch('/:id/:id');
    if (id !== null && id.params.id !== undefined) {
      return id.params.id;
    }
    return 'main';
  }
  const tempPath = useCurrentPath().toLowerCase();
  useEffect(() => {
    const wrap = refWrap.current;
    if (theme !== undefined && theme !== '' && theme === 'invert') {

      wrap.classList.add(styles.wrap_invert);
    } else {

      wrap.classList.remove(styles.wrap_invert);
    }
  }, [theme]);
  useEffect(() => {
    const menu = refMenu.current;
    const overlay = refOverlay.current;
    const body = document.body;
    const root = document.querySelector('#root');
    if (menuOpen) {
      menu.classList.add(styles.active);
      overlay.classList.add(styles.active);
      body.style.overflow = 'hidden';
      root.style.overflow = 'hidden';
    } else {
      menu.classList.remove(styles.active);
      overlay.classList.remove(styles.active);
      body.style.overflow = '';
      root.style.overflow = '';
    }
    return () => {
      menu.classList.remove(styles.active);
      overlay.classList.remove(styles.active);
      body.style.overflow = '';
      root.style.overflow = '';
    }
  }, [menuOpen]);

  useEffect(() => {
    const collapses = refCollapse.current;
    const collapsesBody = refCollapseBody.current;
    const collapsesInner = refCollapseInner.current;
    if (collapses === null) return;
    collapses.forEach((element, index) => {
      if (collapseOpened === index) {
        element.classList.add(styles.open);
        if (collapsesBody[index] !== undefined && collapsesBody[index] !== null) {
          collapsesBody[index].style.height = `${collapsesInner[index].offsetHeight}px`;
        }
      } else {
        element.classList.remove(styles.open);
        if (collapsesBody[index] !== undefined && collapsesBody[index] !== null) {
          collapsesBody[index].style.height = '';
        }
      }
      if (stage === index) {
        element.classList.add(styles.active);
      } else {
        element.classList.remove(styles.active);
      }
    });
  }, [collapseOpened, stage]);

  useEffect(() => {
    const stepElements = refSteps.current;

    // if (stepElements[step] === null || stepElements[step] === undefined) return;
    stepElements.forEach((element, index) => {

      element.classList.remove(styles.active);
      if (step === index) {
        element.classList.add(styles.active);
      }
    });
  }, [step]);

  // useEffect(()=>{
  //   const wrap = refWrap.current;
  //   if(isMobile){
  //     wrap.classList.add(styles.mobile);
  //   }else{
  //     wrap.classList.remove(styles.mobile);
  //   }
  // },[isMobile]);
  const selectLink = (num) => {
    setMenuOpen(false);
    // updateStep(num);
    if (num === 1 && stage === 0) {
      const element = document.querySelector('[data-step-trigger="1"]');
      if (element !== undefined) {
        window.scroll({
          top: element.getBoundingClientRect().top + window.scrollY - refWrap.current.offsetHeight - 10,
          left: 0
        });
      }
    } else {
      window.scroll({
        top: 0,
        left: 0
      });
    }
  };
  return (
    <>
      <header className={styles.wrap} ref={refWrap}>
        <div className={styles.inner}>

          {isMobile && (tempPath === 'grantor' || tempPath === 'competition') ?
            <Link to={tempPath === 'competition' ? 'library' : 'database'} className={styles.back}>
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.73438 7H10.4688" stroke="#61BB1A" strokeWidth="1.52344" strokeLinecap="round" />
                <path d="M5.39062 2.9375L1.53125 7L5.39062 11.0625" stroke="#61BB1A" strokeWidth="1.52344" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              {tempPath === 'competition' ?
                <span>Назад к конкурсам</span>
                :
                <span>Назад к списку</span>
              }

            </Link>
            :
            <div className={styles.logo}>
              <svg width="175" height="35" viewBox="0 0 175 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.6326 26.726H42.6509V8.27426H49.0478C50.1124 8.26201 51.1742 8.38607 52.2068 8.64336C53.074 8.85621 53.8903 9.23695 54.6089 9.76376C55.2738 10.2662 55.799 10.9285 56.1351 11.6881C56.4891 12.47 56.6662 13.3882 56.6664 14.4425C56.6836 15.393 56.4981 16.3363 56.122 17.2104C55.7682 18.0073 55.2265 18.7079 54.5427 19.2532C53.7943 19.8359 52.9373 20.2659 52.021 20.5185C50.9328 20.8213 49.8066 20.9678 48.6766 20.9534H46.6326V26.726ZM46.5528 17.4738H48.7296C49.9327 17.4738 50.8705 17.2409 51.5432 16.7752C52.2155 16.3101 52.5516 15.5852 52.5518 14.6006C52.5518 13.5639 52.2244 12.8303 51.5695 12.3998C50.9149 11.9694 49.9771 11.7541 48.7559 11.7538H46.5528V17.4738Z" fill="#3C3C3B" />
                <path d="M68.7166 27.0424C67.4636 27.0579 66.2181 26.8481 65.0404 26.423C63.9645 26.0294 62.9899 25.4037 62.1869 24.5909C61.362 23.7372 60.7327 22.7165 60.342 21.5988C59.9082 20.4127 59.6915 19.0465 59.6919 17.5003C59.6919 15.9541 59.9175 14.5877 60.3687 13.4013C60.7756 12.2855 61.413 11.2665 62.2399 10.4096C63.0362 9.60122 64.0013 8.97588 65.0671 8.57759C66.1999 8.15676 67.4011 7.94681 68.6106 7.9582C70.0439 7.9582 71.3225 8.17788 72.4463 8.61721C73.5234 9.02568 74.4941 9.66972 75.2864 10.5015C76.0843 11.3665 76.6863 12.3912 77.0519 13.5067C77.4747 14.7956 77.6808 16.1449 77.662 17.5003C77.662 19.1343 77.4364 20.5533 76.9852 21.7573C76.5876 22.8768 75.9439 23.8943 75.1008 24.7359C74.2957 25.5178 73.3255 26.1121 72.2605 26.4757C71.1199 26.8619 69.9217 27.0535 68.7166 27.0424ZM68.8495 23.5628C69.488 23.5696 70.1208 23.4439 70.7075 23.1937C71.2788 22.9438 71.78 22.5592 72.1676 22.0733C72.6043 21.5134 72.9246 20.8729 73.1099 20.189C73.3466 19.3126 73.4582 18.4075 73.4418 17.5003C73.4418 15.409 72.9994 13.8757 72.1145 12.9004C71.2293 11.9253 70.0613 11.4377 68.6106 11.4374C67.9766 11.4333 67.3486 11.5589 66.7657 11.8065C66.184 12.0575 65.6701 12.4414 65.2661 12.9269C64.8098 13.487 64.4713 14.1323 64.2707 14.8246C64.0172 15.6937 63.8964 16.5956 63.9122 17.5003C63.8935 18.4113 64.0234 19.3193 64.2971 20.189C64.5172 20.8799 64.8736 21.5205 65.3455 22.0733C65.7722 22.5631 66.3086 22.9468 66.9116 23.1937C67.5267 23.4424 68.1853 23.5679 68.8495 23.5628V23.5628Z" fill="#3C3C3B" />
                <path d="M95.4998 25.9951C95.2515 26.1177 94.9816 26.2399 94.6902 26.3616C94.3535 26.4988 94.0075 26.6126 93.6549 26.702C93.2013 26.8167 92.7402 26.8998 92.275 26.9507C91.6798 27.0154 91.0814 27.046 90.4827 27.0423C89.1701 27.0586 87.8642 26.8536 86.6206 26.436C85.5123 26.0622 84.4983 25.4552 83.6478 24.6566C82.801 23.8386 82.1482 22.8438 81.7367 21.7442C81.2853 20.5842 81.0596 19.2486 81.0596 17.7375C81.0596 16.1557 81.2853 14.7543 81.7367 13.5331C82.1367 12.3789 82.8 11.3321 83.6749 10.4747C84.5498 9.61735 85.6126 8.9725 86.7801 8.59062C88.1015 8.15158 89.4882 7.93774 90.8815 7.95812C91.7953 7.94893 92.7074 8.03724 93.6022 8.22152C94.2722 8.35476 94.9262 8.55798 95.5532 8.8278L94.6766 12.149C94.1155 11.9572 93.5381 11.816 92.9514 11.7272C92.4509 11.6467 91.9452 11.6027 91.4383 11.5957C90.5898 11.5856 89.7446 11.7013 88.9305 11.939C88.2127 12.1489 87.5507 12.5143 86.9923 13.009C86.4338 13.5204 86.0022 14.1534 85.7313 14.8582C85.4303 15.6073 85.2801 16.4923 85.2806 17.5133C85.2806 19.4683 85.8336 20.939 86.9397 21.9254C88.0449 22.9115 89.5356 23.4047 91.4119 23.4047C91.9293 23.395 92.4447 23.3376 92.9514 23.2333C93.5541 23.119 94.1419 22.9378 94.7038 22.693L95.4998 25.9951Z" fill="#3C3C3B" />
                <path d="M110.629 22.693H103.489L102.162 26.7259H98.2334L104.578 8.27417H109.754L116.018 26.7259H111.957L110.629 22.693ZM104.578 19.2135H109.541L107.073 11.4638L104.578 19.2135Z" fill="#3C3C3B" />
                <path d="M125.07 26.7259H121.088V11.9118H115.806V8.27417H130.326V11.9118H125.07V26.7259Z" fill="#3C3C3B" />
                <path d="M141.209 27.0424C139.956 27.0579 138.71 26.848 137.532 26.423C136.457 26.0295 135.482 25.4038 134.679 24.5909C133.854 23.7373 133.225 22.7166 132.834 21.5988C132.4 20.4127 132.183 19.0465 132.184 17.5003C132.184 15.954 132.409 14.5877 132.861 13.4013C133.268 12.2856 133.905 11.2666 134.732 10.4096C135.528 9.60122 136.493 8.97587 137.559 8.57759C138.692 8.15685 139.893 7.9469 141.103 7.9582C142.536 7.9582 143.815 8.17787 144.938 8.6172C146.016 9.02565 146.986 9.66969 147.779 10.5015C148.576 11.3667 149.178 12.3913 149.544 13.5067C149.967 14.7955 150.173 16.1448 150.154 17.5003C150.154 19.1343 149.929 20.5533 149.478 21.7573C149.08 22.8768 148.436 23.8943 147.593 24.7359C146.788 25.5176 145.817 26.1119 144.753 26.4757C143.612 26.8619 142.414 27.0535 141.209 27.0424V27.0424ZM141.342 23.5628C141.98 23.5696 142.613 23.4439 143.2 23.1937C143.771 22.944 144.272 22.5594 144.659 22.0733C145.096 21.5134 145.417 20.8729 145.602 20.189C145.839 19.3126 145.95 18.4075 145.934 17.5003C145.934 15.409 145.491 13.8757 144.607 12.9004C143.722 11.9253 142.554 11.4377 141.103 11.4374C140.469 11.4333 139.841 11.559 139.258 11.8065C138.676 12.0575 138.162 12.4414 137.758 12.9269C137.302 13.4868 136.963 14.1322 136.763 14.8246C136.509 15.6937 136.388 16.5956 136.405 17.5003C136.386 18.4113 136.516 19.3193 136.789 20.189C137.009 20.88 137.366 21.5205 137.838 22.0733C138.264 22.5633 138.801 22.9471 139.404 23.1937C140.019 23.4425 140.678 23.568 141.342 23.5628Z" fill="#3C3C3B" />
                <path d="M175 26.7259H170.992L169.983 14.4686L166.161 26.7259H162.365L158.595 14.5216L157.587 26.7259H153.579L155.437 8.27417H160.188L164.382 22.1921L168.523 8.27417H173.115L175 26.7259Z" fill="#3C3C3B" />
                <path d="M21.3257 17.5035C21.3251 18.2306 21.1074 18.9412 20.7 19.5454C20.2927 20.1496 19.7141 20.6203 19.0374 20.8979C18.3607 21.1756 17.6162 21.2477 16.8982 21.1052C16.1802 20.9627 15.5209 20.612 15.0036 20.0973C14.4864 19.5827 14.1344 18.9273 13.9923 18.214C13.8502 17.5007 13.9242 16.7616 14.2051 16.0901C14.486 15.4187 14.9611 14.845 15.5703 14.4417C16.1795 14.0383 16.8955 13.8235 17.6277 13.8242C18.6094 13.8253 19.5504 14.2135 20.2439 14.9035C20.9374 15.5934 21.3265 16.5287 21.3257 17.5035V17.5035Z" fill="#11559B" />
                <path d="M17.6583 1.77363e-05C15.0762 -0.00363431 12.5248 0.556746 10.1851 1.64148C13.204 1.33227 16.3393 2.62354 18.6547 4.94411L18.657 4.94315C19.8145 6.07843 20.8295 7.55057 21.6917 9.32229C26.6028 10.1954 29.9381 7.77307 31.2526 6.33962L31.2533 6.33859C29.5973 4.35289 27.5199 2.75505 25.1698 1.65929C22.8196 0.563537 20.2546 -0.00305885 17.6583 1.77363e-05V1.77363e-05Z" fill="#11559B" />
                <path d="M7.0285 3.52866C4.59108 5.35313 2.67068 7.773 1.4541 10.5529C2.9209 10.9113 7.31259 12.3281 9.32747 17.7207C9.93351 17.525 11.2399 16.7702 12.1463 13.8564C13.2584 10.2869 15.2833 6.84206 17.9101 5.31702C15.0149 2.53091 10.6195 1.31225 7.0285 3.52866Z" fill="#11559B" />
                <path d="M32.9443 26.252C34.2384 24.0372 35.0247 21.5662 35.2469 19.0151C34.0076 21.7616 31.3119 23.8079 28.127 24.6354L28.1268 24.6378C26.5562 25.064 24.7627 25.1993 22.7838 25.0537C19.5655 28.8335 20.0139 32.9083 20.609 34.7534L20.6095 34.7546C23.1694 34.3276 25.6026 33.3439 27.735 31.874C29.8674 30.4041 31.6462 28.4843 32.9443 26.252V26.252Z" fill="#11559B" />
                <path d="M35.1769 15.3612C34.801 12.3544 33.6472 9.49557 31.8279 7.06323C30.7814 8.14335 27.3479 11.2054 21.6296 10.2389C21.4976 10.8571 21.5037 12.3561 23.5959 14.5911C26.1581 17.3307 28.155 20.7916 28.1738 23.8093C32.0553 22.7167 35.3175 19.5525 35.1769 15.3612Z" fill="#11559B" />
                <path d="M2.36845 26.2494C3.6572 28.469 5.42258 30.3794 7.53906 31.8448C5.75949 29.4075 5.31981 26.07 6.18934 22.9219L6.18734 22.9205C6.60028 21.359 7.37888 19.7516 8.49548 18.1254C6.80269 13.4725 3.01888 11.8201 1.10948 11.4084L1.10818 11.4082C0.201442 13.8209 -0.155814 16.4031 0.0620928 18.9692C0.279999 21.5353 1.06766 24.0216 2.36845 26.2494Z" fill="#11559B" />
                <path d="M10.7663 33.6115C13.5806 34.7922 16.6544 35.2309 19.6897 34.8852C19.2693 33.4467 18.3112 28.9679 22.0146 24.5417C21.5406 24.1193 20.2281 23.3751 17.2294 24.0538C13.5551 24.8838 9.53333 24.8677 6.88774 23.375C5.90146 27.2538 7.03455 31.6366 10.7663 33.6115Z" fill="#11559B" />
              </svg>
            </div>

          }
          <div className={styles.burger} ref={refBurger} onClick={() => setMenuOpen(true)}>
            {isMobile ?
              <svg width="45" height="28" viewBox="0 0 45 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.1541 8.67188H2" stroke="#11559B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M43.1541 14.8594H25.8464" stroke="#11559B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M43.1541 21.0508H14.5" stroke="#11559B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              :
              <svg width="80" height="40" viewBox="0 0 80 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M73.8466 10.6731H8" stroke="#11559B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M73.8466 20.5769H46.1543" stroke="#11559B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M73.8466 30.4807H28" stroke="#11559B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            }

          </div>
        </div>
      </header>
      <div className={styles.overlay} ref={refOverlay} onClick={() => setMenuOpen(false)}></div>
      <div className={styles.menu} ref={refMenu}>
        <div className={styles.menu__close} onClick={() => setMenuOpen(false)}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.5606 1.99998L2 33.5605" stroke="#11559B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.5606 33.5606L2 2" stroke="#11559B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className={styles.menu__inner}>
          <div className={styles.menu__list}>

            <div className={styles.collapse} ref={element => refCollapse.current[0] = element}>
              <div className={styles.collapse__header}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>Начало</div>
                <Link to={'/'} className={styles.collapse__title} onClick={() => { selectLink(0) }}>Как получить грант на социальный проект?</Link>
              </div>
            </div>

            <div className={styles.collapse} ref={element => refCollapse.current[1] = element}>
              <div className={styles.collapse__header} onClick={() => { if (collapseOpened !== 1) { setCollapseOpened(1) } else { setCollapseOpened(-1) } }}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>Этап 1</div>
                <div className={styles.collapse__title}>
                  <span>Кто ищет грант и на какой проект </span>
                  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9.5 9L18 1" stroke="#11559B" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className={styles.collapse__body} ref={element => refCollapseBody.current[1] = element}>
                <div className={styles.collapse__inner} ref={element => refCollapseInner.current[1] = element}>
                  <Link className={styles.link} to="/#step1" ref={element => refSteps.current[1] = element} onClick={() => { selectLink(1) }}>Определяем тип заявителя</Link>
                  <Link className={styles.link} to="step2" ref={element => refSteps.current[2] = element} onClick={() => { selectLink(2) }}>Определяем тип проекта</Link>
                  <Link className={styles.link} to="step3" ref={element => refSteps.current[3] = element} onClick={() => { selectLink(3) }}>Ингредиенты для идеального проекта</Link>
                </div>
              </div>
            </div>

            <div className={styles.collapse} ref={element => refCollapse.current[2] = element}>
              <div className={styles.collapse__header} onClick={() => { if (collapseOpened !== 2) { setCollapseOpened(2) } else { setCollapseOpened(-1) } }}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>Этап 2</div>
                <div className={styles.collapse__title}>
                  <span>Ищем подходящий грант </span>
                  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9.5 9L18 1" stroke="#11559B" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className={styles.collapse__body} ref={element => refCollapseBody.current[2] = element}>
                <div className={styles.collapse__inner} ref={element => refCollapseInner.current[2] = element}>
                  <Link className={styles.link} to="step4" ref={element => refSteps.current[4] = element} onClick={() => { selectLink(4) }}>Ищем способы поддержки</Link>
                  <Link className={styles.link} to="step5" ref={element => refSteps.current[5] = element} onClick={() => { selectLink(5) }}>Выбираем подходящий грант</Link>
                </div>
              </div>
            </div>

            <div className={styles.collapse} ref={element => refCollapse.current[3] = element}>
              <div className={styles.collapse__header} onClick={() => { if (collapseOpened !== 3) { setCollapseOpened(3) } else { setCollapseOpened(-1) } }}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>Этап 3</div>
                <div className={styles.collapse__title}>
                  <span>Получаем грант</span>
                  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9.5 9L18 1" stroke="#11559B" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className={styles.collapse__body} ref={element => refCollapseBody.current[3] = element}>
                <div className={styles.collapse__inner} ref={element => refCollapseInner.current[3] = element}>
                  <Link className={styles.link} to="step6" ref={element => refSteps.current[6] = element} onClick={() => { selectLink(6) }}>Пишем заявку на грант</Link>
                  <Link className={styles.link} to="step7" ref={element => refSteps.current[7] = element} onClick={() => { selectLink(7) }}>Исправляем ошибки в заявке</Link>
                  <Link className={styles.link} to="step8" ref={element => refSteps.current[8] = element} onClick={() => { selectLink(8) }}>Оцениваем и отправляем заявку</Link>
                </div>
              </div>
            </div>

            <div className={styles.collapse} ref={element => refCollapse.current[4] = element}>
              <div className={styles.collapse__header} onClick={() => { if (collapseOpened !== 4) { setCollapseOpened(4) } else { setCollapseOpened(-1) } }}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>Этап 4</div>
                <div className={styles.collapse__title}>
                  <span>Используем грант</span>
                  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9.5 9L18 1" stroke="#11559B" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className={styles.collapse__body} ref={element => refCollapseBody.current[4] = element}>
                <div className={styles.collapse__inner} ref={element => refCollapseInner.current[4] = element}>
                  <Link className={styles.link} to="step9" ref={element => refSteps.current[9] = element} onClick={() => { selectLink(9) }}>Реализуем проект и отчитываемся за грант</Link>
                  <Link className={styles.link} to="step10" ref={element => refSteps.current[10] = element} onClick={() => { selectLink(10) }}>Что делать, если вы не получили грант</Link>
                </div>
              </div>
            </div>

            <div className={styles.collapse} ref={element => refCollapse.current[5] = element}>
              <div className={styles.collapse__header}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>дополнение</div>
                <Link to={'database'} className={styles.collapse__title} ref={element => refSteps.current[11] = element} onClick={() => { selectLink(11) }}>База грантодателей</Link>
              </div>
            </div>

            <div className={styles.collapse} ref={element => refCollapse.current[6] = element}>
              <div className={styles.collapse__header}>
                <div className={`${textStyles.pretitle} ${styles.collapse__pretitle}`}>дополнение</div>
                <Link to={'library'} className={styles.collapse__title} ref={element => refSteps.current[12] = element} onClick={() => { selectLink(12) }}>Библиотека конкурсов</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.creator}>
          <a href='https://www.infografika.agency/' target='_blank' rel='noreferrer' className={styles.creator__item}>
            <span>Сделано в агентстве </span>
            <img src={`${path}/img/info-logo.svg`} alt="" />
          </a>
          <a href="https://t.me/cptgrantov" target="_blank" rel="noopener noreferrer" className={styles.creator__item}>
            <img src={`${path}/img/cap-logo.webp`} alt="" />
            <span>При методологической поддержке Капитана Грантов</span>
          </a>
        </div>
      </div>
    </>
  );

}

export default Header;
