
import React, { Suspense, useEffect, useState } from "react";
import loadable from '@loadable/component';
import { BrowserRouter as Router, Routes, Route, useMatch } from "react-router-dom";
import PathContext from "./context/path";
import StateContext from "./context/state";
import Wrap from './pages/Wrap/Wrap';
import Header from './components/Header';
import WrapLibrary from './pages/WrapLibrary/WrapLibrary';
import WrapDatabase from './pages/WrapDatabase';
// import StepOne from './pages/StepOne';
// import StepSeven from './pages/StepSeven';
// const Wrap = loadable(()=>import('./pages/Wrap/Wrap'));
const StepOne = loadable(() => import('./pages/StepOne'));
const StepTwo = loadable(() => import('./pages/StepTwo'));
const StepThree = loadable(() => import('./pages/StepThree'));
const StepFour = loadable(() => import('./pages/StepFour'));
const StepFive = loadable(() => import('./pages/StepFive'));
const StepSix = loadable(() => import('./pages/StepSix'));
const StepSeven = loadable(() => import('./pages/StepSeven'));
const StepEight = loadable(() => import('./pages/StepEight'));
const StepNine = loadable(() => import('./pages/StepNine'));
const StepTen = loadable(() => import('./pages/StepTen'));
const Library = loadable(() => import('./pages/Library'));
const Database = loadable(() => import('./pages/Database'));
const Competition = loadable(() => import('./pages/Competition'));
const Grantor = loadable(() => import('./pages/Grantor'));
// import Wrap from './pages/Wrap/Wrap';
// import StepOne from './pages/StepOne';
// import StepTwo from './pages/StepTwo';
// import StepThree from './pages/StepThree';
// import StepFour from './pages/StepFour';
// import StepFive from './pages/StepFive';
// import StepSix from './pages/StepSix';
// import StepSeven from './pages/StepSeven';
// import StepEight from './pages/StepEight';
// import StepNine from './pages/StepNine';
// import StepTen from './pages/StepTen';
const App = () => {
  const [isMobile, setMobile] = useState(true);
  const [page, setPage] = useState(0);
  const [stage, setStage] = useState(-1);
  const [step, setStep] = useState(-1);
  const [endpoint, setEndpoint] = useState(null);
  const updatePage = (number) => setPage(number);
  const updateStage = (number) => setStage(number);
  const updateStep = (number) => setStep(number);
  const basename = '';
  const basenameContext = basename;


  useEffect(() => {
    const handleMobile = () => {
      if (window.innerWidth < 768 && !isMobile) {
        setMobile(true);
      }
      if (window.innerWidth > 767 && isMobile) {
        setMobile(false);
      }
    }
    handleMobile();
    window.addEventListener('resize', handleMobile);
    return () => {
      window.removeEventListener('resize', handleMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    fetch(`${basename}/data/config.json`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.endpoint !== null && data.endpoint !== '') {
          setEndpoint(data.endpoint);
        }
      });
  }, [basename]);

  // const endpoint = 'https://rosatom.devlay.ru/api/';
  

  return (
    <StateContext.Provider value={{ isMobile: isMobile, updatePage: updatePage, updateStage: updateStage, updateStep: updateStep, page: page, stage: stage, step: step, endpoint: endpoint }}>
      <PathContext.Provider value={basenameContext}>
        <Router basename={basename}>

          {/* <div className="wrap">
            <Header /> */}
          <Routes>

            <Route path="/" element={<Wrap />}>
              <Route index element={<StepOne />} />
              <Route path="step2" element={<StepTwo />} />
              <Route path="step3" element={<StepThree />} />
              <Route path="step4" element={<StepFour />} />
              <Route path="step5" element={<StepFive />} />
              <Route path="step6" element={<StepSix />} />
              <Route path="step7" element={<StepSeven />} />
              <Route path="step8" element={<StepEight />} />
              <Route path="step9" element={<StepNine />} />
              <Route path="step10" element={<StepTen />} />
              <Route path='library/' element={<WrapLibrary />}>
                <Route index element={<Library />} />
                <Route path="competition" element={<Competition />} />
              </Route>
              <Route path='database/' element={<WrapDatabase />}>
                <Route index element={<Database />} />
                <Route path="grantor" element={<Grantor />} />
              </Route>
            </Route>
          </Routes>

          {/* </div> */}
        </Router>
      </PathContext.Provider>
    </StateContext.Provider>
  );

}


export default App;
