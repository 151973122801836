import React, { useContext } from "react";
import styles from "./Footer.module.scss";
import PathContext from "../../context/path";
const Footer = () => {
  const path = useContext(PathContext);
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.creator}>
          <a href='https://www.infografika.agency/' target='_blank' rel='noreferrer' className={styles.creator__item}>
            <span>Сделано в агентстве </span>
            <img src={`${path}/img/info-logo.svg`} alt="" />
          </a>
          <a href="https://t.me/cptgrantov" target="_blank" rel="noopener noreferrer" className={styles.creator__item}> 
            <img src={`${path}/img/cap-logo.webp`} alt="" />
            <span>При методологической поддержке Капитана Грантов</span>
          </a>
        </div>
        <div className={styles.socs}>
          <a href='https://t.me/rosatomru' target='_blank' rel='noreferrer' className={styles.link}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M15.4 29.8C23.3529 29.8 29.8 23.3529 29.8 15.4C29.8 7.4471 23.3529 1 15.4 1C7.4471 1 1 7.4471 1 15.4C1 23.3529 7.4471 29.8 15.4 29.8ZM10.2065 16.0957L6.2981 14.7199C5.90063 14.5948 5.90063 14.032 6.2981 13.9069L22.3953 8.02824C22.7265 7.90316 23.0577 8.21585 22.9915 8.52855L20.0768 21.6617C20.0105 21.9744 19.6131 22.0995 19.3481 21.9118L15.4397 19.2227C15.1747 19.0351 14.8435 19.0351 14.6448 19.2227L12.4588 20.9112C12.1938 21.0988 11.7963 21.0363 11.7301 20.7236L10.2065 16.0957ZM19.2156 10.9676L11.5314 15.4078C11.2001 15.5329 11.0676 15.9081 11.2664 16.2208L12.0613 18.9725C12.1275 19.2227 12.4588 19.1601 12.4588 18.9725L12.6575 17.4716C12.6575 17.1589 12.8562 16.9087 13.0549 16.7211L19.3481 11.1552C19.4806 11.0926 19.3481 10.905 19.2156 10.9676Z" fill="#11559B" />
            </svg>
          </a>
          <a href='https://ok.ru/rosatom' target='_blank' rel='noreferrer' className={styles.link}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.8 15.4C29.8 23.3529 23.3529 29.8 15.4 29.8C7.4471 29.8 1 23.3529 1 15.4C1 7.4471 7.4471 1 15.4 1C23.3529 1 29.8 7.4471 29.8 15.4Z" fill="#11559B" />
              <path d="M20.073 17.0803C20.4565 16.6969 20.5113 16.149 20.1826 15.6012C19.9087 15.1629 19.4156 14.9438 18.8678 15.0533C18.6486 15.1081 18.4295 15.2177 18.2104 15.382C16.512 16.5325 14.2111 16.4777 12.5128 15.3272C12.4032 15.2725 12.2936 15.2177 12.1841 15.1629C11.5814 14.889 10.9788 14.9985 10.6501 15.5464C10.2666 16.0942 10.3214 16.6969 10.8145 17.1899C10.924 17.2995 11.0336 17.3543 11.0884 17.4638L11.1432 17.5186C11.9101 18.1213 12.8415 18.5047 14.0467 18.6691L13.3345 19.3813C12.7319 19.9839 12.0745 20.6413 11.4719 21.244C11.3075 21.4083 11.0884 21.6822 11.0884 22.0657C11.0884 22.5588 11.3623 23.0519 11.8554 23.271C12.0197 23.3806 12.1841 23.3806 12.3484 23.3806C12.6223 23.3806 12.951 23.271 13.1702 22.9971C13.992 22.1753 14.6494 21.5179 15.3068 20.8605C15.3616 20.8057 15.3616 20.8057 15.3616 20.8057C15.3616 20.8057 15.4164 20.8057 15.4164 20.8605C15.8546 21.3535 16.3477 21.7918 16.786 22.2849C17.0051 22.504 17.2242 22.7232 17.4434 22.9423C17.7173 23.2162 17.9364 23.3258 18.2651 23.3258C18.7582 23.3806 19.3061 23.0519 19.5252 22.5588C19.7443 22.1205 19.6348 21.5727 19.2513 21.244C18.7034 20.6961 18.1556 20.0935 17.5529 19.5456L16.6216 18.6143C17.1147 18.5595 17.6625 18.45 18.1556 18.2308C18.9773 17.9569 19.58 17.5734 20.073 17.0803Z" fill="white" />
              <path d="M15.3616 15.5464C17.7173 15.5464 19.6348 13.6837 19.6348 11.328C19.6348 10.1775 19.1965 9.08181 18.3747 8.26004C17.5529 7.43828 16.512 7 15.4164 7C13.0606 7 11.1979 8.91746 11.1432 11.2184C11.1432 12.3689 11.5814 13.4098 12.4032 14.2315C13.1702 15.1081 14.2111 15.5464 15.3616 15.5464ZM14.1563 10.0679C14.485 9.73923 14.9233 9.57487 15.3616 9.57487C16.3477 9.57487 17.0599 10.3419 17.0599 11.2732C17.0599 12.2593 16.2929 12.9715 15.3616 12.9715C14.3754 12.9715 13.6633 12.2045 13.6633 11.2732C13.6633 10.8349 13.8276 10.3966 14.1563 10.0679Z" fill="white" />
              <path d="M20.073 17.0803C20.4565 16.6969 20.5113 16.149 20.1826 15.6012C19.9087 15.1629 19.4156 14.9438 18.8678 15.0533C18.6486 15.1081 18.4295 15.2177 18.2104 15.382C16.512 16.5325 14.2111 16.4777 12.5128 15.3272C12.4032 15.2725 12.2936 15.2177 12.1841 15.1629C11.5814 14.889 10.9788 14.9985 10.6501 15.5464C10.2666 16.0942 10.3214 16.6969 10.8145 17.1899C10.924 17.2995 11.0336 17.3543 11.0884 17.4638L11.1432 17.5186C11.9101 18.1213 12.8415 18.5047 14.0467 18.6691L13.3345 19.3813C12.7319 19.9839 12.0745 20.6413 11.4719 21.244C11.3075 21.4083 11.0884 21.6822 11.0884 22.0657C11.0884 22.5588 11.3623 23.0519 11.8554 23.271C12.0197 23.3806 12.1841 23.3806 12.3484 23.3806C12.6223 23.3806 12.951 23.271 13.1702 22.9971C13.992 22.1753 14.6494 21.5179 15.3068 20.8605C15.3616 20.8057 15.3616 20.8057 15.3616 20.8057C15.3616 20.8057 15.4164 20.8057 15.4164 20.8605C15.8546 21.3535 16.3477 21.7918 16.786 22.2849C17.0051 22.504 17.2242 22.7232 17.4434 22.9423C17.7173 23.2162 17.9364 23.3258 18.2651 23.3258C18.7582 23.3806 19.3061 23.0519 19.5252 22.5588C19.7443 22.1205 19.6348 21.5727 19.2513 21.244C18.7034 20.6961 18.1556 20.0935 17.5529 19.5456L16.6216 18.6143C17.1147 18.5595 17.6625 18.45 18.1556 18.2308C18.9773 17.9569 19.58 17.5734 20.073 17.0803Z" fill="white" />
            </svg>

          </a>
          <a href='https://vk.com/rosatomru' target='_blank' rel='noreferrer' className={styles.link}>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.4 0C6.447 0 0 6.447 0 14.4C0 22.353 6.447 28.8 14.4 28.8C22.353 28.8 28.8 22.353 28.8 14.4C28.8 6.447 22.353 0 14.4 0ZM19.938 16.2465C19.938 16.2465 21.2115 17.5035 21.525 18.087C21.534 18.099 21.5385 18.111 21.5415 18.117C21.669 18.3315 21.699 18.498 21.636 18.6225C21.531 18.8295 21.171 18.9315 21.048 18.9405H18.798C18.642 18.9405 18.315 18.9 17.919 18.627C17.6145 18.414 17.3145 18.0645 17.022 17.724C16.5855 17.217 16.2075 16.779 15.8265 16.779C15.7781 16.7789 15.73 16.7865 15.684 16.8015C15.396 16.8945 15.027 17.3055 15.027 18.4005C15.027 18.7425 14.757 18.939 14.5665 18.939H13.536C13.185 18.939 11.3565 18.816 9.7365 17.1075C7.7535 15.015 5.9685 10.818 5.9535 10.779C5.841 10.5075 6.0735 10.362 6.327 10.362H8.5995C8.9025 10.362 9.0015 10.5465 9.0705 10.71C9.1515 10.9005 9.4485 11.658 9.936 12.51C10.7265 13.899 11.211 14.463 11.5995 14.463C11.6723 14.4621 11.7439 14.4436 11.808 14.409C12.315 14.127 12.2205 12.3195 12.198 11.9445C12.198 11.874 12.1965 11.136 11.937 10.782C11.751 10.5255 11.4345 10.428 11.2425 10.392C11.3202 10.2848 11.4226 10.1978 11.541 10.1385C11.889 9.9645 12.516 9.939 13.1385 9.939H13.485C14.16 9.948 14.334 9.9915 14.5785 10.053C15.0735 10.1715 15.084 10.491 15.0405 11.5845C15.027 11.895 15.0135 12.246 15.0135 12.66C15.0135 12.75 15.009 12.846 15.009 12.948C14.994 13.5045 14.976 14.136 15.369 14.3955C15.4203 14.4276 15.4795 14.4448 15.54 14.445C15.6765 14.445 16.0875 14.445 17.2005 12.5355C17.5438 11.9209 17.842 11.2822 18.093 10.6245C18.1155 10.5855 18.1815 10.4655 18.2595 10.419C18.3171 10.3897 18.3809 10.3747 18.4455 10.3755H21.117C21.408 10.3755 21.6075 10.419 21.645 10.5315C21.711 10.71 21.633 11.2545 20.4135 12.906L19.869 13.6245C18.7635 15.0735 18.7635 15.147 19.938 16.2465Z" fill="#11559B" />
            </svg>

          </a>
          <a href='https://dzen.ru/rosatom' target='_blank' rel='noreferrer' className={styles.link}>
            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M15.8008 30C24.0851 30 30.8008 23.2843 30.8008 15C30.8008 6.71573 24.0851 0 15.8008 0C7.51651 0 0.800781 6.71573 0.800781 15C0.800781 23.2843 7.51651 30 15.8008 30ZM15.9893 22.9163C16.1009 19.4565 16.1846 17.8773 17.4123 16.6105C18.679 15.3829 20.2583 15.2992 23.7181 15.1875C23.7181 18.307 23.7069 20.6396 22.5909 21.7891C21.4413 22.9052 19.2148 22.9163 15.9893 22.9163ZM9.22033 21.7891C10.3699 22.9052 12.5964 22.9163 15.8219 22.9163C15.7103 19.4565 15.6266 17.8773 14.3989 16.6105C13.1322 15.3829 11.5529 15.2992 8.0931 15.1875C8.0931 18.307 8.10426 20.6396 9.22033 21.7891ZM8.0931 15.0201C11.5529 14.9085 13.1322 14.8248 14.3989 13.5971C15.6266 12.3304 15.7103 10.7512 15.8219 7.29134C12.602 7.29134 10.3699 7.3025 9.22033 8.41857C8.10426 9.56813 8.0931 11.9007 8.0931 15.0201ZM17.4123 13.5971C16.1846 12.3304 16.0953 10.7512 15.9893 7.29134C19.2148 7.29134 21.4413 7.3025 22.5909 8.41857C23.7069 9.56813 23.7181 11.9007 23.7181 15.0201C20.2583 14.9085 18.679 14.8248 17.4123 13.5971Z" fill="#11559B" />
            </svg>

          </a>
        </div>
      </div>
    </>
  );

}

export default Footer;
