import React, { useContext, useEffect, useState } from "react";
import textStyles from "../../components/Text/Text.module.scss";
import styles from "./WrapDatabase.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import LibContext from "../../context/library";
import Footer from '../../components/Footer/Footer';
import { Outlet } from 'react-router-dom';
const WrapDatabase = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const [isInit, setInit] = useState(false);
  const { isMobile, updateStep, updateStage, endpoint } = state;
  const [databaseInit, setDatabaseInit] = useState(false);
  const [listType, setListType] = useState(0);
  const [initStates, setInitStates] = useState(false);
  const [dataList, setDataList] = useState(
    []
    // [
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    //   {
    //     'img': '/img/lib-list-placeholder.webp',
    //     'text': 'Один из главных грантодателей для некоммерческих организаций России',
    //     'link': 'ngomap.ru',
    //   },
    // ]
  );

  const updateDatabaseInit = () => setDatabaseInit(true);
  useEffect(() => {
    if (endpoint === null || endpoint === undefined || !databaseInit) return;
    // if (localStorage.getItem('cachedDataBatabase')) {
    //   const currentTime = new Date().getTime();
    //   const timeDifference = currentTime - localStorage.getItem('cacheTimeDatabase');
    //   if (timeDifference < 1 * 60 * 1000) {
    //     if (localStorage.getItem('cachedDataBatabase')) {
    //       setDataList(JSON.parse(localStorage.getItem('cachedDataBatabase')).data);
    //       setInitStates(true);
    //     }
    //     return;
    //   }
    // }
    fetch(
      `${endpoint}grantholder/get`,
      {
        method: 'GET',
        redirect: 'follow'
      })
      .then(response => response.json())
      .then(result => {
        setDataList(result.data);
        // localStorage.setItem('cachedDataBatabase', JSON.stringify(result));
        // localStorage.setItem('cacheTimeDatabase', new Date().getTime());
        setInitStates(true);
      })
      .catch(error => console.log('error', error));

  }, [endpoint, databaseInit]);

  useEffect(() => {
    if (isInit) return;
    updateStep(11);
    updateStage(5);
    setInit(true);
  }, [isInit, updateStage, updateStep]);
  return (
    <>
      <LibContext.Provider value={{ dataList: dataList, databaseInit: databaseInit, updateDatabaseInit: updateDatabaseInit }}>
        <div className={styles.wrap}>

          <Outlet />
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      </LibContext.Provider>
    </>
  );

}

export default WrapDatabase;
