import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./WrapLibrary.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import LibContext from "../../context/library";
import Footer from '../../components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import FilterMobile from '../../components/FilterMobile';
import SortPopup from '../../components/SortPopup';
const WrapLibrary = () => {
    const path = useContext(PathContext);
    const state = useContext(StateContext);
    const [isInit, setInit] = useState(false);
    const { isMobile, updateStep, updateStage, endpoint } = state;
    const refWrap = useRef(null);
    const [listType, setListType] = useState(0);
    const [libraryInit, setLibraryInit] = useState(false);
    const [isFilterMobileOpen, setFilterMobileOpen] = useState(false);
    const [dataList, setDataList] = useState(
        []
    );
    const [dataListFiltered, setDataListFiltered] = useState([]);
    const [dataFilters, setDataFilters] = useState([]);
    const [searchQueries, setSearchQueries] = useState({});
    const [searchString, setSearchString] = useState('');
    const [hrefCompetition, setHrefCompetition] = useState(-1);

    const [initStates, setInitStates] = useState([false, false]);
    const [viewCards, setViewCards] = useState(4);
    const [mobileSortPopup, setMobileSortPopup] = useState(false);
    const [mobileSortId, setMobileSortId] = useState(0);
    const [isArchiveState, setArchiveState] = useState(false);
    const updateArchiveState = (bool) => {
        const wrap = refWrap.current;
        wrap.classList.add(styles.transition);
        setTimeout(() => {
            window.scrollTo(0, 0)
            setArchiveState(bool);
        }, 300);
        setTimeout(() => {
            
            wrap.classList.remove(styles.transition);
        }, 400);
    };
    const updateSortPopup = (bool) => setMobileSortPopup(bool);
    const updateMobileSortId = (num) => setMobileSortId(num);
    const updateFilterMobileOpen = (bool) => setFilterMobileOpen(bool);
    const updateLibraryInit = () => setLibraryInit(true);
    const updateHrefCompetition = (num) => setHrefCompetition(num);
    const updateSearchString = (str) => setSearchString(str);
    const updateViewCards = () => {
        if (dataListFiltered.length > viewCards) {
            if (viewCards + 4 <= dataListFiltered.length) {
                setViewCards(viewCards + 4);
            } else {
                setViewCards(dataListFiltered.length);
            }
        }
    }
    const resetQueries = () => setSearchQueries({});
    const updateListType = (num) => setListType(num);

    const updateSearchQueries = (event, query, id, name) => {
        const queries = { ...searchQueries };
        const checkQuery = queries[query] !== undefined;
        const checkId = checkQuery ? queries[query].filter(element => element.id === id).length > 0 : false;
        switch (event) {
            case 'add':
                if (checkId) return;
                if (!checkQuery) {
                    queries[query] = [];
                }
                queries[query].push({
                    'id': id,
                    'name': name
                });
                break;
            case 'remove':
                if (!checkQuery || !checkId) return;
                let removeIndex;
                queries[query].forEach((element, index) => {
                    if (element.id === id) {
                        removeIndex = index;
                    }
                });
                queries[query].splice(removeIndex, 1);
                if (queries[query].length === 0) {
                    delete queries[query];
                }
                break;
            default:
                break;
        }
        setSearchQueries(queries);
    }
    // запрос фильтров
    useEffect(() => {
        if (endpoint === null || endpoint === undefined || !libraryInit) return;
        // if (localStorage.getItem('cachedDataFilter')) {
        //     const currentTime = new Date().getTime();
        //     const timeDifference = currentTime - localStorage.getItem('cacheTime');
        //     if (timeDifference < 1 * 60 * 1000) {
        //         if (localStorage.getItem('cachedDataFilter')) {
        //             setDataFilters(JSON.parse(localStorage.getItem('cachedDataFilter')));
        //             setInitStates(prev => [true, prev[1]]);
        //         }
        //         return;
        //     }
        // }
        fetch(
            `${endpoint}contest/prepare`,
            {
                method: 'GET',
                redirect: 'follow'
            })
            .then(response => response.json())
            .then(result => {
                setDataFilters(result);
                // localStorage.setItem('cachedDataFilter', JSON.stringify(result));
                // localStorage.setItem('cacheTime', new Date().getTime());
                setInitStates(prev => [true, prev[1]]);
            })
            .catch(error => console.log('error', error));

    }, [endpoint, libraryInit]);

    // запрос списка
    useEffect(() => {
        if (endpoint === null || endpoint === undefined || !libraryInit) return;

        // if (localStorage.getItem('cachedDataLib') && searchString.length === 0) {

        //     const currentTime = new Date().getTime();
        //     const timeDifference = currentTime - localStorage.getItem('cacheTime');
        //     if (timeDifference < 1 * 60 * 1000) {
        //         if (localStorage.getItem('cachedDataLib')) {
        //             let array = JSON.parse(localStorage.getItem('cachedDataLib')).data;
        //             setDataList(preFilterDataList(array));
        //             setInitStates(prev => [prev[0], true]);
        //         }
        //         return;
        //     }
        // }
        let search = '';
        if (searchString.length > 0) {
            search = `?search=${searchString}`;
        }
        fetch(
            `${endpoint}contest/get${search}`,
            {
                method: 'GET',
                redirect: 'follow'
            })
            .then(response => response.json())
            .then(result => {
                if (Array.isArray(result.data)) {
                    setDataList(result.data);
                    // if (searchString.length === 0) {

                    //     localStorage.setItem('cacheTime', new Date().getTime());
                    //     localStorage.setItem('cachedDataLib', JSON.stringify(result));
                    // }
                } else if (typeof result.data === 'object' && result.data !== null) {
                    const array = Object.values(result.data);
                    if (Array.isArray(array)) {
                        setDataList(result.data);
                    } else {

                        setDataList([]);
                    }
                    setDataList(result.data);
                } else {
                    setDataList([]);
                }
                setInitStates(prev => [prev[0], true]);
            })
            .catch(error => console.log('error', error));

    }, [endpoint, libraryInit, searchString]);

    useEffect(() => {
        if (!initStates[0] || !initStates[1]) return;

        const preFilterDataList = (array) => {
            let currentArray = [...array];
            if (currentArray.length > 0) {
                currentArray.sort((a, b) => {
                    if (a.fixed) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                currentArray = currentArray.filter((element) => {

                    if (isArchiveState) {

                        return !element.publication;
                    } else {
                        return element.publication;
                    }
                })
            }
            return currentArray;
        }
        let tempDataList = preFilterDataList([...dataList]);
        if (Object.keys(searchQueries).length === 0) {
            setDataListFiltered(tempDataList);
            return;
        }
        const queryFilter = (key, element) => {
            if (searchQueries[key] !== undefined) {
                let tempResult = false;
                searchQueries[key].forEach((query) => {
                    let filter = element[key].filter((item) => item.id === query.id);
                    if (filter.length > 0) {
                        tempResult = true;
                    }
                });
                return tempResult;
            }
        }

        const filteredArray = tempDataList.filter((element) => {
            // let result = true;
            let result = [true];
            Object.entries(searchQueries).forEach(item => {
                let key = item[0];
                if (key === 'years') return;
                result.push(queryFilter(key, element));
            });
            if (searchQueries.years !== undefined) {
                let tempResult = false;
                searchQueries.years.forEach((query) => {
                    let startYear = new Date(element.start_date);
                    let endYear = new Date(element.end_date);

                    if (query.name >= startYear.getFullYear() && query.name <= endYear.getFullYear()) {
                        tempResult = true;
                    }
                });

                result.push(tempResult);
            }
            return result.filter(element => !element).length === 0;
        });
        setDataListFiltered(filteredArray);
    }, [dataList, searchQueries, initStates, isArchiveState]);

    useEffect(() => {
        if (isInit) return;
        updateStep(12);
        updateStage(6);
        setInit(true);
    }, [isInit, updateStage, updateStep]);

    return (
        <>
            <LibContext.Provider value={{ searchQueries: searchQueries, updateSearchQueries: updateSearchQueries, resetQueries: resetQueries, updateListType: updateListType, dataFilters: dataFilters, dataList: dataListFiltered, listType: listType, updateViewCards: updateViewCards, viewCards: viewCards, updateSearchString: updateSearchString, hrefCompetition: hrefCompetition, updateHrefCompetition: updateHrefCompetition, updateLibraryInit: updateLibraryInit, libraryInit: libraryInit, searchString: searchString, isFilterMobileOpen: isFilterMobileOpen, updateFilterMobileOpen: updateFilterMobileOpen, mobileSortPopup: mobileSortPopup, mobileSortId: mobileSortId, updateSortPopup: updateSortPopup, updateMobileSortId: updateMobileSortId, initStates: initStates, updateArchiveState: updateArchiveState, isArchiveState: isArchiveState }}>
                <div className={styles.wrap} ref={refWrap}>
                    {isMobile ?
                        <>
                            <SortPopup />
                            <FilterMobile />
                        </>
                        :
                        null
                    }
                    <Outlet />
                    <div className={styles.footer}>
                        <Footer />
                    </div>
                </div>
            </LibContext.Provider>
        </>
    );

}

export default WrapLibrary;
